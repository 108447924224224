
<template>
  <header class="main-title">
      <label>{{title}} <span v-if="subtitle" class="vertical-bar"> | </span> <span v-if="subtitle">{{subtitle}}</span></label>
  </header>  
</template>

<script>
import { mapState } from 'vuex';
export default {
  mounted(){
  },
  computed: {
    ...mapState([
      'api'
    ]),
  },
  methods:{        
  },
  props:['title','subtitle']
};
</script>

<style scoped>
 .main-title{   
    background: url(../../assets/header.jpg);  
    background-repeat: no-repeat;
    background-size: auto;  
    margin: 0;
    height: 180px; 
    background-size: 100% 180px !important;
    background-repeat: no-repeat !important;
  }
  .main-title{
      font-size: 12px;
      display: flex;
      align-items: flex-end;
  }
  .main-title label{
      margin-left: 100px;
      margin-bottom: 20px;
      color: black;
      font-size: 18px;
      line-height: 39px;
      letter-spacing: normal;
      font-weight: 400;
      width: 100%;
  }
  .main-title span{
      font-size: 20px;
      font-weight: 600;
  }
  .main-title .vertical-bar{
      font-size: 25px;
      font-weight: 400;
  }
</style>

<style>
</style>