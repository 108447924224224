import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#EDA280',
                secondary: '#5E97AD',                            
                error: '#EDA280'
            },
        },
    }
});
