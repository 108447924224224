import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    redirect: '/projects'
  }, 
  {
    path: '/projects',
    name: 'Project List',
    component: () => import('../views/ProjectsList.vue'),
  },
  {
    path: '/projects/:id',
    name: 'Project Detail',
    component: () => import('../components/projectslist/ProjectDetail.vue')
  },
  {
    path: '/application',
    name: 'Reductor application form',
    component: () => import('../views/ReductorApplication.vue')
  },
  {
    path: '*',
    name: '404',
    redirect: '/projects'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
