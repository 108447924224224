import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loadingBG: false,
        api: 'https://api7.cloudframework.io/tneutral/public',
        version: {
            cacheVars: 5,
        },
        vars:{},
        projects: [],
        reductionFormModel: {},
    },
    mutations: {
        SET_LOADING(state,value){
            state.loadingBG = value
        },
        SET_VARS(state, value){
            state.vars = value;
        },      
        SET_REDUCTION_MODEL(state, value){
            state.reductionFormModel = value;
        },      
        SET_PROJECTS(state, value){
            state.projects = value;
        },       
    },
    actions:{
        getVars({commit,state}){
            return new Promise((resolve, reject) => {
                // eslint-disable-next-line no-undef
                if (localStorage.getItem('tnVarsVersion') && state.version.cacheVars == localStorage.getItem('tnVarsVersion')){
                    const resp = localStorage.getItem('tnVars');
                    resolve(JSON.parse(resp));
                    commit('SET_VARS',JSON.parse(resp))
                }else {
                    axios.get(`${state.api}/variables?vars=countries,continents,organization_types,project_status`)
                    .then(response=>{
                        response.data.data.sdg = [
                            {
                                "SDG_Id": 1,
                                "SDG_Name": "No Poverty",
                                "SDG_Code": "1",
                                "SDG_IconURL": "https://tneutral.web.app/assets/sdg-icon/E-WEB-Goal-01.png"
                            },
                            {
                                "SDG_Id": 2,
                                "SDG_Name": "Zero Hunger",
                                "SDG_Code": "2",
                                "SDG_IconURL": "https://tneutral.web.app/assets/sdg-icon/E-WEB-Goal-02.png"
                            },
                            {
                                "SDG_Id": 3,
                                "SDG_Name": "Good Health and Well-being",
                                "SDG_Code": "3",
                                "SDG_IconURL": "https://tneutral.web.app/assets/sdg-icon/E-WEB-Goal-03.png"
                            },
                            {
                                "SDG_Id": 4,
                                "SDG_Name": "Quality Education",
                                "SDG_Code": "4",
                                "SDG_IconURL": "https://tneutral.web.app/assets/sdg-icon/E-WEB-Goal-04.png"
                            },
                            {
                                "SDG_Id": 5,
                                "SDG_Name": "Gender Equality",
                                "SDG_Code": "5",
                                "SDG_IconURL": "https://tneutral.web.app/assets/sdg-icon/E-WEB-Goal-05.png"
                            },
                            {
                                "SDG_Id": 6,
                                "SDG_Name": "Clean Water and Sanitation",
                                "SDG_Code": "6",
                                "SDG_IconURL": "https://tneutral.web.app/assets/sdg-icon/E-WEB-Goal-06.png"
                            },
                            {
                                "SDG_Id": 7,
                                "SDG_Name": "Affordable and Clean Energy",
                                "SDG_Code": "7",
                                "SDG_IconURL": "https://tneutral.web.app/assets/sdg-icon/E-WEB-Goal-07.png"
                            },
                            {
                                "SDG_Id": 8,
                                "SDG_Name": "Decent Work and Economic Growth",
                                "SDG_Code": "8",
                                "SDG_IconURL": "https://tneutral.web.app/assets/sdg-icon/E-WEB-Goal-08.png"
                            },
                            {
                                "SDG_Id": 9,
                                "SDG_Name": "Industry, Innovation and Infrastructure",
                                "SDG_Code": "9",
                                "SDG_IconURL": "https://tneutral.web.app/assets/sdg-icon/E-WEB-Goal-09.png"
                            },
                            {
                                "SDG_Id": 10,
                                "SDG_Name": "Reduced Inequality",
                                "SDG_Code": "10",
                                "SDG_IconURL": "https://tneutral.web.app/assets/sdg-icon/E-WEB-Goal-10.png"
                            },
                            {
                                "SDG_Id": 11,
                                "SDG_Name": "Sustainable Cities and Communities",
                                "SDG_Code": "11",
                                "SDG_IconURL": "https://tneutral.web.app/assets/sdg-icon/E-WEB-Goal-11.png"
                            },
                            {
                                "SDG_Id": 12,
                                "SDG_Name": "Responsible Consumption and Production",
                                "SDG_Code": "12",
                                "SDG_IconURL": "https://tneutral.web.app/assets/sdg-icon/E-WEB-Goal-12.png"
                            },
                            {
                                "SDG_Id": 13,
                                "SDG_Name": "Climate Action",
                                "SDG_Code": "13",
                                "SDG_IconURL": "https://tneutral.web.app/assets/sdg-icon/E-WEB-Goal-13.png"
                            },
                            {
                                "SDG_Id": 14,
                                "SDG_Name": "Life Below Water",
                                "SDG_Code": "14",
                                "SDG_IconURL": "https://tneutral.web.app/assets/sdg-icon/E-WEB-Goal-14.png"
                            },
                            {
                                "SDG_Id": 15,
                                "SDG_Name": "Life on Land",
                                "SDG_Code": "15",
                                "SDG_IconURL": "https://tneutral.web.app/assets/sdg-icon/E-WEB-Goal-15.png"
                            },
                            {
                                "SDG_Id": 16,
                                "SDG_Name": "Peace and Justice Strong Institutions",
                                "SDG_Code": "16",
                                "SDG_IconURL": "https://tneutral.web.app/assets/sdg-icon/E-WEB-Goal-16.png"
                            },
                            {
                                "SDG_Id": 17,
                                "SDG_Name": "Partnerships to achieve the Goal",
                                "SDG_Code": "17",
                                "SDG_IconURL": "https://tneutral.web.app/assets/sdg-icon/E-WEB-Goal-17.png"
                            }
                        ]
                        localStorage.setItem('tnVarsVersion',state.version.cacheVars)
                        localStorage.setItem('tnVars',JSON.stringify(response.data.data));
                        commit('SET_VARS',response.data.data)
                        resolve(response.data)
                    })
                    .catch(error=>{
                        reject(error)
                    })
                }                
            })
        }
    }
});