<template>
  <v-app>    
    <Header2 title="T-NEUTRAL" :subtitle="subtitle" />
    <v-main>
      <v-container>  
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Header2 from './components/common/Header2.vue'
export default {
  name: 'App',

  components: {
    Header2
  },

  data: () => ({subtitle: null}),
  watch:{
    $route(to){
      switch (to.path) {
        case '/projects':
          this.subtitle = 'CENTRAL REGISTRY'
          break;
        case '/application':
          this.subtitle = 'REDUCTION PROJECT ACCOUNT APPLICATION FORM'
          break;
        default:
          this.subtitle = 'REDUCTION PROJECTS'
          break;
      }
    }
  }
};
</script>
<style>
.container{
  max-width: 100% !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
}
</style>